<template>
  <div class="noq-widget" :style="{ height: noqWidgetContainerHeight }">
    <div class="noq-widget-list-part">
      <div class="noq-widget-header">
        <h5 class="noq-widget-header-title">
          <a :href="locatorUrl" target="_blank">
            {{ hostName }}
          </a>
        </h5>
        <div class="noq-widget-logo">
          <img :src="widgetLogo">
        </div>
        <div class="noq-widget-header-descr">
          Hier können Sie Ihren Schnelltest in unserer Nähe buchen:
        </div>
        <div class="noq-widget-filter-wrapper">
          <map-filters :show-person-filter="false" />
        </div>
      </div>
      
      <div v-if="dateTimeFilter" class="noq-widget-list-wrapper">
        <locations-list
          :locations="listLocations"
          :recent-location="recentLocation"
          :date-time-filter="dateTimeFilter"
          :show-button="false"
          :show-footer="false"
          :is-widget="true"
          :max-locations="10"
          :vertical-widget-sort="isVertical"
          @mouse-over-location="setHighlightedLocation"
        />
      </div>
      <div v-if="isVertical" class="noq-widget-footer"></div>
    </div>
    <div v-if="!isVertical" class="noq-widget-map-part">
      <google-map
        :locations="locations"
        :my-location="recentLocation"
        :my-location-zoom-level="zoomLevelValue"
        :highlighted-location="highlightedLocation"
        :is-widget="true"
        :map-height="heightValue"
        @visible-markers-changed="updateLocationsList"
      />
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/plugins/event-bus.js'
import elasticQueryMixin from '@/mixins/elastic_query'
import GoogleMap from '@/components/GoogleMap.vue'
import LocationsList from '@/components/LocationsList.vue'
import MapFilters from '@/components/MapFilters.vue'

const DEFAULT_WIDGET_HEIGHT = 450

export default {
  name: 'LocatorWidget',
  components: {
    GoogleMap,
    LocationsList,
    MapFilters
  },
  mixins: [elasticQueryMixin],
  props: {
    location: {
      type: Object,
      required: false,
      default: null
    },
    format: {
      type: String,
      required: false,
      default: 'vertical',
      validator: function(value) {
        return ['horizontal', 'vertical'].includes(value)
      }
    },
    height: {
      type: Number,
      required: false,
      default: DEFAULT_WIDGET_HEIGHT,
      validator: function(value) {
        return [300, 450, 600, 740].includes(value)
      }
    },
    zoomLevel: {
      type: Number,
      required: false,
      default: 12
    }
  },
  data() {
    return {
      locations: [],
      listLocations: [],
      recentLocation: null,
      dateTimeFilter: null,
      formatValue: 'vertical',
      zoomLevelValue: this.zoomLevel,
      heightValue: this.height,
      highlightedLocation: null
    }
  },
  computed: {
    widgetLogo() {
      return process.env.VUE_APP_LOCATOR_LOGO
    },
    hostName() {
      return location.host
    },
    locatorUrl() {
      return location.protocol + "//" + location.host
    },
    isVertical() {
      return this.formatValue === 'vertical'
    },
    noqWidgetContainerHeight() {
      return `${this.heightValue}px`
    }
  },
  mounted() {
    EventBus.$on('date-time-filter-changed', dateTimeValue => {
      this.fetchLocations(dateTimeValue)
    })

    if ('location' in this.$route.query) {
      const queryCoords = this.$route.query.location.split(',')
      this.recentLocation = {
        lat: parseFloat(queryCoords[0]),
        lng: parseFloat(queryCoords[1])
      }
    } else if (this.location) {
      this.setRecentLocation(this.location)
    }

    if ('height' in this.$route.query) {
      this.setWidgetHeight(this.$route.query.height)
    }

    if ('format' in this.$route.query) {
      this.formatValue = this.$route.query.format
    }

    if ('zoomLevel' in this.$route.query) {
      this.zoomLevelValue = parseInt(this.$route.query.zoomLevel)
    }
  },
  watch: {
    format(newVal) {
      this.formatValue = newVal
    },
    height(newVal) {
      this.setWidgetHeight(newVal)
    },
    location(newVal) {
      if (newVal) {
        this.setRecentLocation(newVal)
      }
    },
    zoomLevel(newVal) {
      this.zoomLevelValue = newVal
    }
  },
  methods: {
    fetchLocations(dateTimeFilter) {
      this.dateTimeFilter = dateTimeFilter
      this.getLocationsByDateTime(dateTimeFilter)
        .then(data => {
          this.locations = data
          this.listLocations = data
        })
    },
    setWidgetHeight(height) {
      this.heightValue = this.isHeightValid(height) ? parseInt(height) : DEFAULT_WIDGET_HEIGHT
      document.documentElement.style.setProperty('--widget-height', `${this.heightValue}px`)
    },
    isHeightValid(height) {
      return [300, 450, 600, 740].includes(parseInt(height))
    },
    setRecentLocation(location) {
      this.recentLocation = {
        lat: location.geometry.location.lat(),
        lng: location.geometry.location.lng()
      }
    },
    updateLocationsList(emitedVisibleLocations) {
      this.listLocations = emitedVisibleLocations
    },
    setHighlightedLocation(location) {
      this.highlightedLocation = location
    }
  }
}
</script>

<style lang="scss" scoped>
.noq-widget {
  .noq-widget-list-part {
    width: 300px;
    display: inline-block;

    .noq-widget-header {
      padding: $spacing-xs;
      background-color: $color_white;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
      z-index: 2;

      .noq-widget-header-title {
        display: inline-block;
        width: 65%;

        a {
          text-decoration: none;
          color: $color_black;
        }
      }

      .noq-widget-logo {
        display: inline-block;
        width: 35%;
        vertical-align: middle;
        text-align: right;
        margin-bottom: $spacing-3xs;

        img {
          height: 25px;
          padding-top: $spacing-4xs;
        }
      }

      .noq-widget-header-descr {
        @include text_sm;
        color: $color_gray;
        margin-top: $spacing-xxs;
        margin-bottom: $spacing-m;
      }
    }

    .noq-widget-footer {
      height: 11px;
      width: 100%;
      background: linear-gradient(270deg, #68BD45 0.37%, #00340F 81.73%, #000000 104.18%);
    }
  }

  .noq-widget-map-part {
    display: inline-block;
    width: calc(100% - 300px);
    height: 100%;
  }
}
</style>

<style lang="scss">
.noq-widget-list-wrapper {
  .location-items-wrapper {
    margin-top: 0;
    height: calc(var(--widget-height) - #{$widget-header-height} - 11px);

    .location-item {
      border-bottom: 1px solid $color_forms-border;

      .location-item-name-wrapper {
        max-width: 190px;
        width: 190px;
      }
    }
  }
}
</style>
