var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"location-item",class:{ 'location-item__is-active': _vm.locationItem.isActive },on:{"click":_vm.locationItemClicked}},[_c('div',{staticClass:"location-item-logo",style:({
      'background-image': ("url(" + _vm.getLogo + "), url(" + _vm.getDefaultLogo + ")")
    })}),_c('div',{staticClass:"location-item-name-wrapper"},[_c('div',{staticClass:"location-item-name"},[_vm._v(" "+_vm._s(_vm.locationItem.name)+" ")]),_c('div',{staticClass:"location-item-address"},[_c('span',{staticClass:"location-item-address-name",class:{
          'location-item-address-name__fixed-width': _vm.isWidget
        }},[_vm._v(" "+_vm._s(_vm.locationItem.address)+", "+_vm._s(_vm.locationItem.city)+" ")]),(_vm.recentLocationDistance)?_c('span',{staticClass:"location-item-address-distance"},[_vm._v(" • "+_vm._s(_vm.recentLocationDistance)+" ")]):_vm._e()]),(_vm.allIntegrations.length > 0)?_c('div',{staticClass:"location-item-integrations"},[(_vm.locationItem.appIntegrations.includes('cwa'))?_c('div',{staticClass:"app-integration-icon",style:({
          'background-image': ("url(" + (require ('@/assets/icons/cwa_app_icon.png')))
        })}):_vm._e(),(_vm.locationItem.appIntegrations.includes('dcc'))?_c('div',{staticClass:"app-integration-icon",style:({
          'background-image': ("url(" + (require ('@/assets/icons/dcc_icon.png')))
        })}):_vm._e(),(_vm.locationItem.appIntegrations.includes('luca'))?_c('div',{staticClass:"app-integration-icon",style:({
          'background-image': ("url(" + (require ('@/assets/icons/luca_app_icon.png')))
        })}):_vm._e()]):_vm._e()]),(!_vm.isWidget)?_c('div',{staticClass:"location-item-btn-wrapper"},[_c('a',{staticClass:"noq-button noq-button__primary noq-button__sm",class:{ 'noq-button__disabled': _vm.locationItem.free <= 0 },attrs:{"href":"#"},on:{"click":[function($event){_vm.navToBooking(_vm.bookingLink(_vm.locationItem, _vm.dateTimeFilter.date))},function($event){$event.stopPropagation();}]}},[(_vm.locationItem.free > 0 && _vm.locationItem.slimTestbuchen)?[_vm._v(" "+_vm._s(_vm.$t('components.LocationItem.checkAvailability'))+" ")]:(_vm.locationItem.free > 0 && !_vm.locationItem.slimTestbuchen)?[_vm._v(" "+_vm._s(_vm.locationItem.free)+" "+_vm._s(_vm.$t('components.LocationItem.places'))+" ")]:(_vm.locationItem.booketOut)?[_vm._v(" "+_vm._s(_vm.$t('components.LocationItem.booketOut'))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('components.LocationItem.notAvailable'))+" ")]],2)]):_c('div',{staticClass:"location-item-values-wrapper"},[_c('div',{staticClass:"location-item-values-free"},[_vm._v(" "+_vm._s(_vm.locationItem.free)+" ")]),_c('div',{staticClass:"location-item-values-label"},[_vm._v(" free ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }