<template>
  <div class="home">
    <div class="mobile-home">
      <div class="mobile-home-header-wrapper">
        <header-info v-if="isMobile"/>
      </div>
      <div class="mobile-home-body-wrapper">
        <home-page-info />
      </div>
      <footer-info />
    </div>
    <div class="desktop-home">
      <map-locator v-if="!isMobile" />
    </div>
  </div>
</template>

<script>
import FooterInfo from '@/components/FooterInfo.vue'
import HeaderInfo from '@/components/HeaderInfo.vue'
import HomePageInfo from '@/components/HomePageInfo.vue'
import MapLocator from '@/components/MapLocator.vue'

export default {
  name: 'Home',
  data() {
    return {
      windowWidth: window.innerWidth
    }
  },
  created(){
    window.addEventListener('resize', () => {this.windowWidth = window.innerWidth});
  },
  components: {
    FooterInfo,
    HeaderInfo,
    HomePageInfo,
    MapLocator
  },
  computed: {
    isMobile() {
      return this.windowWidth < 996
    }
  }
}
</script>

<style scoped lang="scss">
.mobile-home {
  display: none;

  @media (max-width: $breakpoint-tablet) {
    display: block;
  }

  max-width: 694px;
  margin: 0 auto;
  position: relative;
  min-height: 100%;
  margin-bottom: -21px;

  .mobile-home-header-wrapper {
    margin-bottom: $spacing-4xl;
  }

  .mobile-home-body-wrapper {
    margin-bottom: $spacing-6xl;
  }
}

.desktop-home {
  display: block;

  @media (max-width: $breakpoint-tablet) {
    display: none;
  }
}
</style>
