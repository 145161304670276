import { DateTime } from "luxon";

export default {
  data(){
    return {
      bookingUrl: process.env.VUE_APP_BOOKING_BASE_URL
    }
  },
  methods: {
    bookingLink(locationItem, date) {
      const dateString = new DateTime.fromObject(date)
      if (this.$mode == "ia") {
        return `${this.bookingUrl}${locationItem.slug}/checkins/?source=ia#/0/${dateString.toISODate()}`
      } else {
        return `${this.bookingUrl}${locationItem.slug}/checkins/#/0/${dateString.toISODate()}`
      } 
    }
  }
}
