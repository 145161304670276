<template>
  <div class="noq-map-component">
    <div class="map-container">
      <div
        ref='googleMap'
        class='google-map'
        :style="widgetStyle"
      ></div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/plugins/event-bus.js'

const MY_LOCATION_PIN = require('@/assets/icons/noq-map-pin-my-location.svg')
const ACTIVE_PIN = require('@/assets/icons/noq-map-pin-active.svg')
const UNAVAILABLE_PIN = require('@/assets/icons/noq-map-pin-inactive.svg')
const AVAILABLE_PIN = require('@/assets/icons/noq-map-pin-normal.svg')

const SCASLED_PIN_WIDTH_PX = 36.4
const SCASLED_PIN_HEIGHT_PX = 51.8
const NORMAL_PIN_WIDTH_PX = 27
const NORMAL_PIN_HEIGHT_PX = 43

const MY_LOCATION_DEFAULT_ZOOM_LEVEL = 15

const clusterImageSmall = require('@/assets/images/no-q_map-cluster-small.png')
const clusterImageMedium = require('@/assets/images/no-q_map-cluster-medium.png')
const clusterImageBig = require('@/assets/images/no-q_map-cluster-big.png')


const MAP_CENTER = process.env.VUE_APP_MAP_INITIAL_CENTER.split(',')

export default {
  name: 'GoogleMap',
  props: {
    locations: {
      type: [Array],
      required: true
    },
    highlightedLocation: {
      type: Object,
      required: false
    },
    clickedLocation: {
      type: Object,
      required: false
    },
    myLocation: {
      type: Object,
      required: false
    },
    searchLocation: {
      type: Object,
      required: false
    },
    isWidget: {
      type: Boolean,
      required: false,
      default: false
    },
    mapHeight: {
      type: Number,
      required: false,
      default: 300
    },
    myLocationZoomLevel: {
      type: Number,
      required: false,
      default: 12
    }
  },
  data() {
    return {
      map: null,
      bounds: new window.google.maps.LatLngBounds(),
      mapOptions: {
        mapId: "1a3cc5077f0cfd94",
        center: { lat: parseFloat(MAP_CENTER[0]), lng: parseFloat(MAP_CENTER[1]) },
        zoomControl: true,
        zoomControlOptions: {
          position: 9 // google.maps.ControlPosition.BOTTOM_RIGHT
        },
        disableDefaultUI: true,
        zoom: parseInt(process.env.VUE_APP_MAP_INITIAL_ZOOM),
        gestureHandling: 'greedy'
      },
      // map clusters styles
      clusterStyle: [
        {
          url: clusterImageSmall,
          width: 40,
          height: 40,
          anchorText: [0, 0],
          anchorIcon: [28, 28],
          textColor: '#102A43',
          textSize: 16
        },
        {
          url: clusterImageMedium,
          width: 46,
          height: 46,
          anchorText: [0, 0],
          anchorIcon: [28, 28],
          textColor: '#102A43',
          textSize: 16
        },
        {
          url: clusterImageBig,
          width: 52,
          height: 52,
          anchorText: [0, 0],
          anchorIcon: [28, 28],
          textColor: '#102A43',
          textSize: 16
        }
      ],
      markers: [],
      activeMarker: null,
      visibleMarkers: [],
      markerClusterer: null,
      hoveredMarker: null,
      myLocationMarker: null,
      // filters
      personValue: 1,
      testType: 'all',
      autoscale: true,
      searchMarker: null
    }
  },
  computed: {
    isListVisible() {
      return this.visibleMarkers.length > 0
    },
    widgetStyle() {
      if (!this.isWidget) {
        return {}
      }
      return { height: `${this.mapHeight}px` }
    },
    // mapContainerHeight() {
    //   if (!this.isWidget) {
    //     return '100vh'
    //   }
    //   return `${this.mapHeight}px`
    // }
  },
  mounted() {
    EventBus.$on('person-filter-changed', personsAmount => {
      this.applyPersonFilter(personsAmount)
    })

    EventBus.$on('date-time-filter-changed', dateTimeValue => {
      this.fetchLocations(dateTimeValue)
    })

    if ('lat' in this.$route.query && 'lng' in this.$route.query) {
      this.autoscale = false
      this.mapOptions.center = {
        lat: parseFloat(this.$route.query.lat),
        lng: parseFloat(this.$route.query.lng)
      }
    }

    this.initMap()

    if (this.isWidget) {
      this.autoscale = false

      if (this.myLocation) {
        this.showMyLocation()
      }
    }

    if ('zoom' in this.$route.query) {
      this.autoscale = false
      this.map.setZoom(parseInt(this.$route.query.zoom))
    }
  },
  watch: {
    locations() {
      this.initLocations()
    },
    activeMarker(valToEmit){
      this.$emit('active-location-changed', valToEmit)
    },
    highlightedLocation(newVal) {
      if (newVal) {
        const pos = { lat: newVal.lat, lng: newVal.lng }

        this.hoveredMarker = new window.google.maps.Marker({
          position: pos,
          map: this.map,
          icon: this.getMarkerIcon(newVal, true)
        })
      } else if (this.hoveredMarker) {
        this.hoveredMarker.setMap(null)
      }
    },
    clickedLocation(newVal) {
      if (newVal) {
        this.markerClickHandler(newVal)
      }
    },
    myLocation(newVal) {
      if (newVal) {
        this.showMyLocation()
      }
    },
    searchLocation(newVal) {
      if (newVal) {
        this.navigate(newVal)
      } else {
        this.removeSearchMarker()
      }
    },
    myLocationZoomLevel(newVal) {
      this.map.setZoom(newVal)
    }
  },
  methods: {
    navigate(location) {
      this.map.setCenter(location.geometry.location);
      this.map.setZoom(parseInt(process.env.VUE_APP_SEARCH_ZOOM_LEVEL))

      if (this.searchMarker) {
        this.searchMarker.setMap(null)
      }

      //geolocation marker will be needer to find closest pharmacies
      //has to be reset after every search. now they stay on the map
      this.searchMarker = new window.google.maps.Marker({
        map: this.map,
        position: location.geometry.location
      })
    },
    initMap() {
      if (this.$mode == 'ia') {
        this.mapOptions.center = {lat: 48.134905, lng: 11.582264}
        this.mapOptions.zoom = 12
      }

      this.map = new window.google.maps.Map(this.$refs.googleMap, {
        ...this.mapOptions
      })
      this.geocoder = new window.google.maps.Geocoder();

      this.map.addListener('click', () => {
        this.markerClickHandler(null)
        this.activeMarker = null
      })

      window.google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
        if (this.locations.length > 0) {
          this.initLocations()
        }
        this.setVisibleMarkers()
      })

      window.google.maps.event.addListener(this.map, 'idle', () => {
        this.setVisibleMarkers()
        this.updateUrlWithZoomAndCenter()
      })
    },
    initialAutoScale(){
      //TODO auto scaling should only happen on initial load
    },
    initLocations() {
      // create Markers
      const locations = this.locations

      this.markers = locations.reduce((markers, location) => {
        if (!!location.lat && !!location.lng && !!location.slug) {
          // set locations for auto zoom map
          const setLocations = new window.google.maps.LatLng(location.lat, location.lng)
          this.bounds.extend(setLocations)

          // set Markers on Map
          const marker = new window.google.maps.Marker({
            position: location,
            map: this.map,
            // label: location.name,
            title: location.name,
            icon: this.getMarkerIcon(location),
            slug: location.slug,
            lat: location.lat,
            lng: location.lng,
            name: location.name,
            free: location.free,
            address: location.address,
            zip: location.zip,
            city: location.city,
            country: location.country,
            isActive: false,
            booketOut: location.booketOut,
            appIntegrations: location.appIntegrations,
            slimTestbuchen: location.slimTestbuchen,
            website: location.website,
            prices: location.prices
          })

          window.google.maps.event.addListener(marker, 'click', () => {
            this.markerClickHandler(marker)
          })

          markers.push(marker);
        }
        return markers;
      }, []);

      // create MarkerClusterer and add Image
      this.markerClusterer = new window.MarkerClusterer(
        this.map,
        this.markers,
        {
          styles: this.clusterStyle,
          maxZoom: 12,
          minimumClusterSize: 5
        }
      )

      // autoScale
      if (this.autoscale && !this.myLocation) this.map.fitBounds(this.bounds)
      this.autoscale = false
      this.setVisibleMarkers()
    },
    clearLocations() {
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null)
      }
      this.visibleMarkers = []
      this.markers = []
      if (this.markerClusterer) {
        this.markerClusterer.clearMarkers()
      }
      
    },
    setVisibleMarkers() {
      this.visibleMarkers = []
      if (this.map && this.map.getBounds()) {
        this.markers.forEach(m => {
          if (this.map.getBounds().contains(m.getPosition())) {
            this.visibleMarkers.push(m)
          }
        })
      }
      this.$emit('visible-markers-changed', this.visibleMarkers)
    },
    markerClickHandler(marker) {
      // find currently active pin and make inactive
      if (this.activeMarker) {
        const activeMarker = this.markers.find(m => {
          return this.activeMarker.slug === m.slug
        })

        activeMarker.setIcon(
          this.getMarkerIcon(activeMarker)
        )
        activeMarker.isActive = false
      }

      if (marker) {
        // find pin clicked on active
        this.activeMarker = marker
        marker.setIcon(
          this.getMarkerIcon(marker, true)
        )
        marker.isActive = true
        this.map.panTo(marker.position)
      }
    },
    getMarkerIcon(marker, isActive = false) {
      const pinWidth = isActive ? SCASLED_PIN_WIDTH_PX : NORMAL_PIN_WIDTH_PX
      const pinHeight = isActive ? SCASLED_PIN_HEIGHT_PX : NORMAL_PIN_HEIGHT_PX
      let icon = this.getPinIcon(marker)
      if (isActive) {
        icon = ACTIVE_PIN
      }

      return {
        url: icon,
        scaledSize: {
          width: pinWidth,
          height: pinHeight,
          f: 'px',
          b: 'px'
        }
      }
    },
    getPinIcon(location) {
      return location.free >= this.personValue ? AVAILABLE_PIN : UNAVAILABLE_PIN
    },
    showMyLocation() {
      this.map.setCenter(this.myLocation)
      this.setMyLocationZomLevel()

      if (!this.myLocationMarker) {
        this.myLocationMarker = new window.google.maps.Marker({
          position: this.myLocation,
          map: this.map,
          icon: {
            url: MY_LOCATION_PIN,
            scaledSize: {
              width: SCASLED_PIN_WIDTH_PX,
              height: SCASLED_PIN_HEIGHT_PX,
              f: 'px',
              b: 'px'
            }
          }
        })
      } else {
        this.myLocationMarker.setPosition(this.myLocation)
      }
    },
    setMyLocationZomLevel() {
      if (this.isWidget) {
        this.map.setZoom(this.myLocationZoomLevel)
      } else {
        this.map.setZoom(MY_LOCATION_DEFAULT_ZOOM_LEVEL)
      }
    },
    fetchLocations(dateTimeFilter) {
      this.clearLocations()
      this.$emit("date-time-changed", dateTimeFilter)
    },
    applyPersonFilter(personValue) {
      this.personValue = personValue
      this.applyFilters()
    },
    applyFilters() {
      this.markers.forEach(m => {
        m.setIcon(
          this.getMarkerIcon(m, false)
        )
      })
    },
    removeSearchMarker() {
      if (this.searchMarker) {
        this.searchMarker.setMap(null)
        this.searchMarker = null
      }
    },
    updateUrlWithZoomAndCenter() {
      this.$router
          .push({
            name: this.$route.name,
            query: {
              ...this.$route.query,
              zoom: this.map.getZoom(),
              lat: this.map.getCenter().lat(),
              lng: this.map.getCenter().lng()
            }
          })
          .catch(() => {})
    }
  }
}
</script>


<style lang="scss">
.noq-map-component {
  .map-container {
    position: relative;

    .google-map {
      width: 100%;
      height: 100vh;

      @media (max-width: $breakpoint-tablet) {
        height: calc(var(--app-height) - #{$location-search-height} - #{$ads-container-height});
      }

      // HACK TO HIDE GOOGLE MAPS ZOOM CONTROLS
      .gmnoprint:first-child > .gmnoprint {
        @media (max-width: $breakpoint-tablet) {
          display: none !important;
        }
      }
    }
  }
}
</style>
