<template>
  <div class="home-footer">
    <a href="https://www.no-q.info" target="_blank">
      {{ $t('views.Home.serviceProvidedByNoQ') }}
    </a>
    &nbsp;•&nbsp;
    <a v-if="false" href="https://www.no-q.info/corona-test-management/schnittstellen-fuer-unternehmen/" target="_blank">
      APIs
    </a>
    <template v-if="isCookiesVisible">
      <router-link :to="{ name: 'cookiePolicy' }">
        Cookie Policy
      </router-link>
      &nbsp;•&nbsp;
    </template>
    <a href="https://www.no-q.info/impressum/" target="_blank">
      {{ $t('views.Home.imprint') }}
    </a>
    &nbsp;•&nbsp;
    <a href="https://www.no-q.info/datenschutz/" target="_blank">
      {{ $t('views.Home.privacy') }}
    </a>
    &nbsp;•&nbsp;
    <router-link :to="{ name: 'widgetBuilder' }">
      Widget
    </router-link>
  </div>
</template>

<script>
export default {
  computed: {
    isCookiesVisible() {
      return process.env.VUE_APP_COOKIEBOT_ENABLED === 'true'
    }
  }
}
</script>
<style lang="scss" scoped>
.home-footer {
  @include text_body;
  padding: 22px 0 19px 0;
  text-align: center;
  margin: 0 auto;
  width: 100%;

  height: 21px;

  a {
    text-decoration: none;
    color: unset;
  }
}
</style>
