<template>
  <div class="ga-container">
    <div :id="tagId"></div>
  </div>
</template>

<script>
export default {
  props: {
    placement: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      gpt: null
    }
  },
  computed: {
    tagId() {
      return "div-gpt-ad-1631706677060-0-"+this.placement
    }
  },
  mounted() {
    this.gpt = this.GoogleTag
    this.initAds()
    this.showAds()
  },
  beforeDestroy() {
    this.gpt.destroySlots();
  },
  methods: {
    initAds() {
      let id = this.tagId
      let gpt = this.gpt
      let size = this.placement == 'desktop' ? [468, 60] : [320,50]
      gpt.cmd.push(function() {
        gpt.defineSlot('/140533388/testbuchen.de_responsive', [size], id)
        .addService(gpt.pubads());
        gpt.pubads().enableSingleRequest();
        gpt.enableServices();
      });
    
    },
    showAds() {
      let id = this.tagId
      let gpt = this.gpt
      gpt.cmd.push(function() {
        gpt.display(id); 
      });
    }
  }
}
</script>

<style lang="scss" scoped>
  .ga-container {
    width: 100%;
    height: $ads-container-height;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>