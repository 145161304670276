import axios from 'axios'
import { DateTime } from "luxon";

const TAGS = process.env.VUE_APP_QUERY_TAGS.split(',')

export default {
  data(){
    return {
      elasticUrl: process.env.VUE_APP_ELASTIC_URL,
      dateTimeFilter: null,
    }
  },
  methods: {
    async getLocationsByDateTime(dateTimeFilter) {
      this.dateTimeFilter = dateTimeFilter
      let query = this.buildElasticLocationsQuery()
      return axios.get(this.elasticUrl, {
        params: {
          source: JSON.stringify(query),
          source_content_type: 'application/json'
        },
      })
      .then(resp => this.normaliseElasticData(resp.data))
      .catch(err => console.log(err))
    },
    normaliseElasticData(data) {
      return data.aggregations.gyms.buckets.map(loc => {
        let locInfo = loc.gym.hits.hits[0]._source
        let booketOut = false
        if (loc.total_free_spots.value <= 0 && loc.placeholder.buckets.some(b => b.key === 0)) {
          booketOut = true
        }
        return {
          slug: locInfo.slug,
          lat: locInfo.lat,
          lng: locInfo.lng,
          name: locInfo.name,
          free: loc.total_free_spots.value,
          address: locInfo.address,
          zip: locInfo.zip,
          city: locInfo.city,
          country: locInfo.country,
          booketOut: booketOut,
          appIntegrations: locInfo.app_integrations,
          slimTestbuchen: locInfo.slim_testbuchen,
          website: locInfo.website,
          prices: locInfo.prices
        }
      })
    },
    buildElasticLocationsQuery() {
      let now = new DateTime.local();
      let selectedDate = this.dateTimeFilter.date
      let selectedTime = this.dateTimeFilter.time
      let from = null
      let to = null

      // timeRange can be 00:00 - 01:00
      if (selectedTime !== null) {
        if (now.day == selectedDate.day) {
          if (selectedTime > now.hour) {
            from = new DateTime.local(selectedDate.year, selectedDate.month, selectedDate.day, selectedTime)
          }
          to = new DateTime.local(selectedDate.year, selectedDate.month, selectedDate.day, selectedTime+1)
        } else {
          from = new DateTime.local(selectedDate.year, selectedDate.month, selectedDate.day, selectedTime)
          to = new DateTime.local(selectedDate.year, selectedDate.month, selectedDate.day, selectedTime+1)
        }
      } else {
        if (now.day == selectedDate.day) {
          to = now.endOf('day')
        } else {
          from = new DateTime.local(selectedDate.year, selectedDate.month, selectedDate.day).startOf('day')
          to = new DateTime.local(selectedDate.year, selectedDate.month, selectedDate.day).endOf('day')
        }
      }
      
      let filters = []

      if (this.$mode == "ia") {
        filters.push({"terms": {"tags": ["ia"]}})
      } else {
        filters.push({"terms": {"tags": TAGS}})
      }
      
      let appFilter = this.dateTimeFilter.integrations && this.dateTimeFilter.integrations.length > 0
      let testTypeFilter = this.dateTimeFilter.testType

      if (appFilter) {
        filters.push({
          "terms": {"app_integrations": this.dateTimeFilter.integrations},
        })
      }

      if (testTypeFilter && testTypeFilter != 'any') {
        filters.push({
          "exists": {"field": `prices.${testTypeFilter}`},
        })
      }

      return {
        "size":0,
        "query": {
          "bool": {
            "filter": [
              ...filters,
              {
                "bool": {
                  "should": [
                    {
                      "bool": {
                        "filter": [
                          {
                            "range": {
                              "checkin_at": {
                                "gte": from ? from.toISO() : 'now',
                                "lt": to.toISO()
                              }
                            }
                          },
                          {
                            "range": {
                              "min_booking_date": {
                                "lte": "now"
                              }
                            }
                          }
                        ]
                      }
                    },
                    {
                      "term": {
                        "placeholder": true
                      }
                    }
                  ]
                }
              }              
            ]
          }
        },
        "aggs": {
          "gyms":{
             "terms":{
                "field":"gym_id",
                "size": 10000
             },
             "aggs":{
                "gym":{
                   "top_hits":{
                      "_source":"*",
                      "size":1
                   }
                },
                "total_free_spots":{
                   "sum":{
                      "field":"free_spots"
                   }
                },
                "placeholder": {
                  "terms": { "field": "placeholder" }
                }
             }
          }
       }
      }
    },
  }
}