// import { Trans } from '@/plugins/translation'
import Home from '@/views/Home.vue'
import CookiePolicy from '@/views/CookiePolicy.vue'
import TestVacLanding from '@/views/TestVacLanding.vue'
import VaccinationList from '@/views/VaccinationList.vue'
import LocatorWidget from '@/views/LocatorWidget.vue'
import PharmaMap from '@/views/PharmaMap.vue'
import WidgetBuilder from '@/views/WidgetBuilder.vue'

const st_vac_locator = Boolean(process.env.VUE_APP_VAC_LANDING_MODE) == true
const homeComponent = st_vac_locator ? TestVacLanding : Home

let routes = [
  {
    path: '/',
    name: 'home',
    component: homeComponent
  },
  {
    path: '/map',
    name: 'pharmaMap',
    component: PharmaMap
  },
  {
    path: '/widget',
    name: 'locatorWidget',
    component: LocatorWidget
  },
  {
    path: '/widget-builder',
    name: 'widgetBuilder',
    component: WidgetBuilder
  },
  {
    path: '/cookie-policy',
    name: 'cookiePolicy',
    component: CookiePolicy
  }
]

if (st_vac_locator) {
  routes.push({
    path: '/list',
    name: 'vaccinationList',
    component: VaccinationList
  })
}

export default routes

// export default [
//   {
//     path: '/:lang',
//     component: {
//       template: '<router-view></router-view>'
//     },
//     beforeEnter: Trans.routeMiddleware,
//     children: [
//       {
//         path: 'home',
//         name: 'home',
//         component: Home
//       },
//       {
//         path: '',
//         name: 'pharmaMap',
//         component: PharmaMap
//       }
//     ]
//   },
//   {
//     // Redirect user to supported lang version.
//     path: '*',
//     redirect () {
//       return Trans.getUserSupportedLang()
//     }
//   }
// ]
