// import { version } from '../../package.json'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import axios from 'axios'
// import defaultLang from '@/locales/default.json'

Vue.use(VueI18n)

const SUPPORTED_LANGUAGES = process.env.VUE_APP_SUPPORTED_LANGUAGES.split(',')
const translationFolder = process.env.VUE_APP_QUERY_TAGS
const languages = {}
SUPPORTED_LANGUAGES.forEach(l => {
  languages[l] = require(`@/locales/${translationFolder}/${l}.json`)
})

// const DEFAULT_LANGUAGE = process.env.VUE_APP_DEFAULT_LANGUAGE
const FALLBACK_LANGUAGE = process.env.VUE_APP_FALLBACK_LANGUAGE
// const TRANSLATIONS_URL = process.env.VUE_APP_TRANSLATIONS_URL

export const i18n = new VueI18n({
  locale: process.env.VUE_APP_DEFAULT_LANGUAGE, // set locale
  fallbackLocale: FALLBACK_LANGUAGE,
  messages: languages
})

// axios
//   .get(`${TRANSLATIONS_URL}${DEFAULT_LANGUAGE}.json?version=${version}`)
//   .then(({ data }) => {
//     i18n.setLocaleMessage(DEFAULT_LANGUAGE, data)
//   })
//   .catch(err => console.log(err))
